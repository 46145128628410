@import './../../../../scss/mixins';

#report-form-details-root {
  width: 100%;
  height: 100%;
  padding-left: 26px;

  .form-details-header {
    width: 100%;
    height: 10%;
    display: flex;
    align-items: center;

    i {
      color: #424c9f;
      font-size: 18px;
    }

    p {
      color: #4a4a4a;
      font-size: 24px;
      margin-bottom: 0;
    }
  }

  .form-details-content {
    width: 100%;
    height: 90%;

    .form-details-content-wrapper {
      width: 95%;
      height: 100%;

      .form-details-content-primary {
        width: 100%;
        min-height: 25%;
        border-radius: 10px 10px 0 0;
        background-color: #e5eaef;
        display: flex;

        .primary-img-wrapper {
          width: 170px;
          display: flex;
          justify-content: center;
          align-items: center;

          .primary-img {
            width: 100px;
            height: 100px;
            border-radius: 50px;
            background-color: #ffffff;

            img {
              width: 100%;
              height: 100%;
              border-radius: 50%;
              cursor: pointer;
            }
          }
        }

        .primary-fields {
          width: 60%;
          padding: 15px 0;
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;

          .field-data-wrapper {
            width: 50%;
            padding-bottom: 10px;

            h6 {
              color: #4a4a4a;
              font-size: 12px;
              margin-bottom: 0;
              // padding-bottom: 5px;
            }

            h5 {
              color: #4a4a4a;
              font-size: 16px;
              margin-bottom: 0;
            }
          }

          .contact-details {
            width: 50%;
            padding-bottom: 10px;
            display: flex;
            align-items: center;

            i {
              font-size: 15px;
              color: #00acf1;
              padding-right: 15px;
            }

            p {
              font-size: 16px;
              color: #4a4a4a;
              margin-bottom: 0;
            }
          }
        }
      }

      .form-details-content-secondary {
        width: 100%;
        min-height: 75%;

        .appbar-root {
          color: #ffffff;
          font-size: 16px;
          background-color: #00acf1;
          box-shadow: none;
        }

        .button-root {
          color: #ffffff;
          font-size: 16px;
          opacity: 0.7;

          &:focus {
            outline: none;
          }
        }

        .selected-button {
          font-size: 18px;
          font-weight: bold;
          opacity: 1
        }

        .tab-label {
          margin-right: 20px;
          margin-left: 20px;
          display: inline-block;
        }

        .indicator-bar {
          height: 3px;
          margin-bottom: 5px;
          background-color: #ffffff;
        }

        .field-data-wrapper {
          padding-bottom: 10px;

          h6 {
            color: #4a4a4a;
            font-size: 12px;
            padding-bottom: 5px;
            margin-bottom: 0;
          }

          h5 {
            color: #4a4a4a;
            font-size: 16px;
            margin-bottom: 0;
            width: 95%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            // @include line-clamp(38px, 19px);
          }

          .longtext-span {
            color: #4a4a4a;
            font-size: 16px;
            margin-bottom: 0;
          }
        }

        .activity-data-wrapper {
          padding-bottom: 10px;
          width: 100%;

          h6 {
            color: #4a4a4a;
            font-size: 14px;
            margin-bottom: 0;
          }

          h5 {
            color: #4a4a4a;
            font-size: 16px;
            margin-bottom: 0;
          }

          .data-wrapper {
            display: flex;
            align-items: center;
            padding-bottom: 2px;
          }
        }

        .w25-pb30 {
          width: 25%;
          padding-bottom: 30px;
        }

        .button-wrapper {
          padding-bottom: 20px;
          display: flex;
          justify-content: center;
          align-items: center;

          .form-update-button {
            @include button(320px, 45px, 25px, 18px, 24px, #00acf1, #ffffff);
          }

          .form-update-disabled-button {
            @include button(320px,
              45px,
              25px,
              18px,
              24px,
              rgba(0, 0, 0, 0.12),
              rgba(0, 0, 0, 0.26));
          }
        }

        .longtext-wrapper {
          width: 95%;
          display: flex;
          flex-direction: row;
          align-items: center;

          .longtext-span-wrapper {
            width: 80%;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
          }
        }
      }
    }
  }
}