#workflow-inbox-root {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;

  .workflow-inbox-wrapper {
    width: 95%;
    height: 100%;

    .workflow-inbox-header {
      width: 100%;
      height: 10%;
      display: flex;
      align-items: center;

      .workflow-inbox-title-wrapper {
        width: 55%;
        display: flex;
        align-items: center;

        i {
          color: #424c9f;
          font-size: 18px;
        }

        p {
          color: #4a4a4a;
          font-size: 24px;
          margin-bottom: 0;
          width: 85%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }

    .workflow-inbox-filter-search {
      width: 50%;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      i {
        font-size: 18px;
        color: #424c9f;
      }
    }

    .views-search-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 20px;

      .views-picky {
        display: flex;
        align-items: center;
        width: 50%;

        p {
          color: #4a4a4a;
          margin-bottom: 0px;
          font-size: 16px;
          margin-right: 20px;
        }

        button {
          &:focus {
            outline: none;
          }
        }

        .picky {
          width: 50%;
        }
      }

      .picky__input {
        height: 40px;
        border: 1px solid #cfd3d4;
        border-radius: 3px;
        padding: 1px 7px 2px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        &::after {
          top: 13px;
        }
      }

      .picky__dropdown {
        border: 1px solid #cfd3d4;
      }

      .picky__input,
      .picky__dropdown,
      .option {
        font-size: 14px;
      }

      .picky__dropdown .option:focus,
      .picky__dropdown li:focus {
        outline: none !important;
      }
    }

    .workflow-inbox-content {
      width: 100%;
      height: 90%;

      .paper-root {
        box-shadow: none !important;
        overflow-x: auto;

        .table-wrapper {
          margin-bottom: 0;

          th {
            vertical-align: middle;
            height: 30px;
            width: 42px;
            font-size: 16px;
            color: #ffffff;
            word-wrap: break-word;
            text-align: left;
            padding: 4px 4px 4px 10px;
          }

          td {
            vertical-align: middle;
            height: 30px;
            width: 42px;
            font-size: 14px;
            color: #4a4a4a;
            word-wrap: break-word;
            text-align: left;
            padding: 4px 4px 4px 10px;
          }

          .tabel-head-wrapper {
            height: 30px;
            background-color: #00acf1;
            border-radius: 3px;

            tr {
              .checkbox-color {
                svg {
                  color: #ffffff;
                }
              }
            }
          }

          .tabel-body {
            tr {
              &:nth-child(even) {
                background-color: #f1f4f7;
              }

              &:nth-child(odd) {
                background-color: #ffffff;
              }

              td {
                max-width: 42px;
                border: none;
                border-right: solid 1px #979797;

                &:last-child {
                  border-right: none;
                }

                svg {
                  color: #979797;
                }

                i {
                  color: #4a4a4a;
                  font-size: 16px;
                }
              }
            }
          }

          .tabel-footer {
            tr {
              td {
                font-size: 15px;
                border: none !important;

                button {
                  &:focus {
                    outline: none;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.MuiTablePagination-toolbar {
  margin-right: 15px;
  padding-top: 5px;
}

.MuiTablePagination-selectLabel {
  padding-top: 3px !important;
}

.MuiTablePagination-selectLabel,
.MuiTablePagination-displayedRows {
  margin-top: 10px;
  font-size: 14px !important;
}